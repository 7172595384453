























































































































import {
  BSidebar,
  BForm,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BDropdown,
  BFormGroup,
  BDropdownItem,
  BFormInput,
  BPagination,
  BFormSelect,
  BRow,
  BTable,
  BSpinner,
} from "bootstrap-vue";
import VueSlider from "vue-slider-component";
import 'vue-slider-component/theme/default.css';
import { Component, Prop, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { DetailsUtilisateur } from "@/api/models/utilisateurs/utilisateur";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { MembreCompetenceCreateModel } from "@/api/models/membresCompetences/membreCompetence";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    SearchableVueSelect,
    VueSlider
  },
  name: "create-membre",
})
export default class AjoutMembre extends Vue {
  @Prop({ required: true, type: Boolean })
  isAddNewCompetenceSidebarActive!: boolean;
  loading = false;

  required = required;

  listMembres: any[] = [];
  totalCountMembre: any = null

  membre: MembreCompetenceCreateModel = {
    note : 1
  };
  direction = "ltr";
  optionCategorie = ['Titulaire', 'Remplacant'];

  async created() {
    await this.listUsers()
  }

  resetForm() {
    this.membre = {
      note: 1
    };
  }

  async listUsers(){
    await this.$http.myressif.utilisateurs
      .paginatedList()
      .then((res: PaginatedList<DetailsUtilisateur>) => {
        this.listMembres = res.items.map((x: DetailsUtilisateur) => <DetailsUtilisateur>{
            ...x,
            completeLabel: `${x.nom} ${x.prenom}`
        })
        this.totalCountMembre = res.totalCount
        }
      )
  }

  async submit() {
    this.loading = true;
    this.membre.membreId = this.membre.membre?.id;
    await this.$http.myressif.competences
      .addMembre(this.$route.params.id, this.membre)
      .then(
        async (response: any) => {
          successAlert.fire({
            timer: 3500,
            title: "Ajout d'un membre",
            text: "Membre ajouté avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(async () => {
        await this.$emit("refresh-competences");
        this.$emit("update:is-add-new-competence-sidebar-active", false);
        this.loading = false;
      });
  }
}
